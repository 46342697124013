<template>
    <div>
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ข้อมูลอัตรากำลัง สอจ."
          class="px-5 py-3"
        >
          <v-row no-gutters>
             <v-col cols="12" md="12">
          <div id="chart">
            <v-card id="chart">
              <apexchart
                type="pie"
                width="50%"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </div>
        </v-col>

            <v-col cols="12" md="12">
              <v-card>
                <div id="chart">
                  <apexchart
                    type="bar"
                    height="500"
                    :options="chartOptionsbar"
                    :series="seriesbar"
                  ></apexchart>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
            single-line
            hide-details
            dense
            filled
            class="mb-2"
          />
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="personnel_etcs"
            :search="search"
            disable-pagination
          >
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "personnel_etc",
    data() {
      return {
        VueMoment,
        moment,
        loading: false,
        ApiKey: "HRvec2021",
        valid: true,
  
        personnel_etcs: [],
        search: "",
        pagination: {},
        headers: [
          {
            text: "วิทยาลัย",
            align: "center",
            value: "college_name"
          },
          { text: "ตำแหน่ง", align: "left", value: "position_name" },
          {
            text: "วิทยฐานะ",
            align: "center",
            value: "rang_name"
          },
          {
            text: "จำนวน",
            align: "center",
            value: "countRang"
          },
        
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        chartOptionsbar: {
          chart: {
            type: "bar",
            height: 500,
            stacked: true,         
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              }
            },
            zoom: {
              enabled: true,
              type: "x",
              autoScaleYaxis: true,
              zoomedArea: {
                fill: {
                  color: "#90CAF9",
                  opacity: 0.4
                },
                stroke: {
                  color: "#0D47A1",
                  opacity: 0.4,
                  width: 1
                }
              }
            }
          },
  
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
  
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          },
          legend: {
            position: "right",
            offsetY: 40
          },
          fill: {
            opacity: 1
          }
        },
        seriesbar: [
          { name: [], data: [] },      
        ],
        numX: [],
        numY: [],
        numZ: [],
        numAss: [],
           series: [],
      chartOptions: {
        chart: {
          width: "30%",
          type: "pie"
        },

        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }
        },
        selection: {
          enabled: true
        },
        labels: [],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: "จำนวนข้าราชการครู"
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          }
        },
        legend: {
          position: "left"
        }
      },
      numXpie: [],
      };
    },
    created() {
      this.personnel_etcQueryAll();
      this.personnel_etcQueryPieAll();
    },
  
    methods: {
      async personnel_etcQueryAll() {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;       
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            province_ID: userSession.user_province_ID,
            group_rang: "Ok",

          })
          .then(result => {
            result.data.forEach(element => {
              this.numX.push(element.collegeIni + ' : ' + element.rang_name);
              this.numY.push(element.countRang);            
          
            });
            this.seriesbar = [
              {
                data: this.numY
              },             
            ];
            this.chartOptionsbar = { xaxis: { categories: this.numX } };
            this.personnel_etcs = result.data;
          })
          .finally(() => (this.loading = false));
      },
         async personnel_etcQueryPieAll() {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;       
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            province_ID: userSession.user_province_ID,
            group_Grouprang: "Ok",

          }).then(result => {
            result.data.forEach(element => {
              this.numXpie.push(element.rang_name);              
          this.series.push(parseInt(element.countRang));
            });          
           this.chartOptions = { labels: this.numXpie };        
          })
          .finally(() => (this.loading = false));
      }
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  